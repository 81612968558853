import Dexie from 'dexie';
import distanceBetweenPoints from './location-utils';
import LocationEntry from './location-entry';

const createLocationComparator = (range) => {
  const fn = (a, b) => distanceBetweenPoints(
    a.latitude,
    a.longitude,
    b.latitude,
    b.longitude,
  ) < range;
  return fn;
};

const locationComparator = createLocationComparator(500);

export default class LocationStore {
  static p_db;

  get db() {
    if (this.p_db) return this.p_db;

    const db = new Dexie('JKM-locations');
    db.version(1).stores({ locations: '++id' });
    db.version(2).stores({ locations: '++id, updatedAt' });
    this.p_db = db;
    db.table('locations').mapToClass(LocationEntry);
    return db;
  }

  static async getLocation() {
    if (!('geolocation' in navigator)) return null;
    try {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          maximumAge: 60 * 1000,
        });
      });
      return pos;
    } catch {
      return null;
    }
  }

  async getEntriesHere() {
    const pos = await this.constructor.getLocation();
    if (!pos) return [];

    return this.db.locations
      .orderBy('updatedAt').reverse()
      .filter((i) => locationComparator(pos.coords, i)).toArray();
  }

  async addEntryHere(name) {
    const pos = await this.constructor.getLocation();
    if (!pos) return null;
    const data = {
      name,
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
      updatedAt: new Date(),
    };
    const id = await this.db.locations.put(data);
    data.id = id;
    return new LocationEntry(data);
  }

  async delete(entry) {
    await this.db.locations.delete(entry.id);
  }

  async update(entry) {
    await this.db.locations.update(entry.id, {
      name: entry.name,
      updatedAt: new Date(),
    });
  }
}
