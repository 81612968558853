const distanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // metres
  const degToRad = Math.PI / 180;
  const φ1 = lat1 * degToRad; // φ, λ in radians
  const φ2 = lat2 * degToRad;
  const Δφ = (lat2 - lat1) * degToRad;
  const Δλ = (lon2 - lon1) * degToRad;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
              + Math.cos(φ1) * Math.cos(φ2)
              * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};

export default distanceBetweenPoints;
