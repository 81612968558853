import './index.html';

import LocationStore from './location-store';
import card from './card';
import scanner from './scanner';

const locationStore = new LocationStore();
const { gotoCard } = card(locationStore);
scanner(gotoCard, locationStore);

document.querySelector('body').style.display = 'block';

(async () => {
  console.log('LOCATIONS: ', await locationStore.getEntriesHere(), await LocationStore.getLocation());
})();
