import distanceBetweenPoints from './location-utils';

export default class LocationEntry {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
    this.updatedAt = data?.updatedAt;
  }

  setOrigin(coord) {
    this.coord = coord;
  }

  get distanceToOrigin() {
    if (!this.coord) return null;
    return distanceBetweenPoints(
      this.latitude,
      this.longitude,
      this.coord.lantitude,
      this.coord.longitude,
    );
  }
}
