import Dexie from 'dexie';

export default class GeneralStore {
  static p_db;

  get db() {
    if (this.p_db) return this.p_db;

    const db = new Dexie('JKM-kv');
    db.version(1).stores({ kv: 'key' });
    this.p_db = db;
    return db;
  }

  async getName() {
    return (await this.db.kv.get('name'))?.value;
  }

  async saveName(name) {
    return this.db.kv.put({ key: 'name', value: name });
  }
}
