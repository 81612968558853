import './scanner.css';
import QrScanner from './qr-scanner.min';

export default (gotoCard, locationStore) => {
  let scanner;

  function extractCommunityName(text) {
    let m = text.match(/query=([^&=]+)/);
    if (!m) return null;
    const qs = decodeURIComponent(m[1]);
    m = qs.match(/communityName=([^&=]+)/);
    if (!m) return null;
    return m[1];
  }

  function gotQR(result) {
    const name = extractCommunityName(result.data);
    if (!name) return;
    if (scanner) scanner.stop();
    gotoCard(name);
  }

  const zooms = [3, 5, 10];
  let zoom = 0;

  const s = document.querySelector('#scanner');

  function startScanning() {
    scanner = new QrScanner(s, (result) => gotQR(result), {
      highlightScanRegion: true,
      highlightCodeOutline: true,
      calculateScanRegion: (video) => {
        const smallestDimension = Math.min(video.videoWidth, video.videoHeight);
        const scanRegionSize = Math.round((2 / zooms[zoom]) * smallestDimension);
        const R = Math.min(1000, scanRegionSize);
        return {
          x: Math.round((video.videoWidth - scanRegionSize) / 2),
          y: Math.round((video.videoHeight - scanRegionSize) / 2),
          width: scanRegionSize,
          height: scanRegionSize,
          downScaledWidth: R,
          downScaledHeight: R,
        };
      },
    });
    scanner.start().then(() => { });

    document.querySelectorAll('#stored-locations button:not(.stay)').forEach((i) => i.remove());
  }

  startScanning();
  document.querySelector('#scanner-wrapper').addEventListener('click', () => {
    zoom += 1;
    if (zoom >= zooms.length) zoom = 0;
    // eslint-disable-next-line no-underscore-dangle
    scanner._onLoadedMetaData();
  });

  document.addEventListener('contextmenu', (event) => event.preventDefault());
  document.querySelector('#scanner-add').addEventListener('click', () => {
    if (scanner) scanner.stop();
    gotoCard('');
  });

  locationStore.getEntriesHere().then((entries) => {
    const wrapper = document.querySelector('#stored-locations');
    entries.forEach((e) => {
      const button = document.createElement('button');
      wrapper.appendChild(button);
      button.textContent = e.name;
      button.addEventListener('click', () => {
        if (scanner) scanner.stop();
        gotoCard(e.name, e);
      });
    });
  });

  document.querySelector('#stored-locations-cover').addEventListener('click', () => {
    document.querySelector('#stored-locations').style.visibility = 'visible';
  });
};
