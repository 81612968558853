import './card.css';
import dayjs from 'dayjs';
import GeneralStore from './general-store';

const generalStore = new GeneralStore();

export default (locationStore) => {
  let activeLocationEntry;
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('sw.js')
      .then(() => { console.log('Service Worker Registered'); });
  }

  function refreshDate() {
    const m = dayjs();
    document.querySelector('#date-m').textContent = m.format('MM');
    document.querySelector('#date-d').textContent = m.format('DD');
    document.querySelector('#time2').textContent = m.format('HH:mm:ss');
  }
  refreshDate();
  setInterval(refreshDate, 1000);

  const gotoCard = async (name, locationEntry) => {
    document.querySelector('#location').textContent = name;
    document.querySelector('#wrapper').style.display = 'grid';
    document.querySelector('#scanner-wrapper').style.display = 'none';
    document.querySelector('#name').textContent = (await generalStore.getName()) ?? '屈*氏';

    if (locationEntry) {
      activeLocationEntry = locationEntry;
      return;
    }
    if (name) {
      activeLocationEntry = await locationStore.addEntryHere(name);
    } else {
      activeLocationEntry = await locationStore.addEntryHere(`${dayjs().format('MM-DD HH:mm')}`);
    }
  };

  const entryWrapper = document.querySelector('#entry-wrapper');
  document.querySelector('#main-qr').addEventListener('dblclick', () => {
    entryWrapper.style.display = 'grid';

    if (activeLocationEntry) {
      document.querySelector('#entry-name').value = activeLocationEntry.name;
      document.querySelector('#entry-delete').style.display = 'inline';
    } else {
      document.querySelector('#entry-name').value = document.querySelector('#location').textContent;
      document.querySelector('#entry-delete').style.display = 'none';
    }
  });
  document.querySelector('#entry-save').addEventListener('click', async () => {
    const name = document.querySelector('#entry-name').value;
    if (activeLocationEntry) {
      activeLocationEntry.name = name;
      await locationStore.update(activeLocationEntry);
    } else {
      activeLocationEntry = await locationStore.addEntryHere(name);
    }
    document.querySelector('#location').textContent = name;
    entryWrapper.style.display = 'none';
  });
  document.querySelector('#entry-delete').addEventListener('click', async () => {
    if (activeLocationEntry) {
      await locationStore.delete(activeLocationEntry);
      activeLocationEntry = null;
    }
    entryWrapper.style.display = 'none';
  });

  const nameWrapper = document.querySelector('#name-wrapper');
  document.querySelector('#name-reveal').addEventListener('dblclick', () => {
    nameWrapper.style.display = 'grid';
    document.querySelector('#entry-name').value = document.querySelector('#name').textContent;
  });
  document.querySelector('#name-save').addEventListener('click', async () => {
    const name = document.querySelector('#name-name').value;
    await generalStore.saveName(name);
    document.querySelector('#name').textContent = name;
    nameWrapper.style.display = 'none';
  });

  const overlay = document.querySelector('#orig');
  document.querySelector('#tracking-card').addEventListener('dblclick', () => {
    overlay.style.display = 'block';
  });
  overlay.addEventListener('click', () => {
    overlay.style.display = 'none';
  });

  return { gotoCard };
};
